import React from "react";
import CommonProduct from "../components/commonPage"
import Button from '@mui/material/Button'
import { Link } from "gatsby"

import { navigate } from "gatsby"

class NotFound extends CommonProduct {
    render() {
        if (typeof window !== "undefined" && window.location.pathname.includes('/product/ultid/')){
            let newURL = window.location.pathname.replace(/.*\/product\/ultid\//, '/product/?ultid=')

            navigate(newURL)

            return <></>
        }

        let BaseComponent = this.BaseComponentRender.bind(this)
        return (
            <BaseComponent controller={this} menu="page404" title="404 Not Found | EdTech Index" className="page">
              <div className="notfoundimg"/>
            <h1>Sorry, this page does not exist.</h1>

            
            <p className="statement">
              <Link to="/">Return to EdTech Index.</Link>
            </p>
            </BaseComponent>
        )
    }
}
export default NotFound